import React, { useEffect, useState, useRef } from "react";
import confetti from "canvas-confetti";
import "./Modal.css";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { SocialIcon } from "react-social-icons";

const Modal = ({
  correctAnswersCount,
  onClose,
  showConfetti,
  streakCounter,
  category,
  solved,
  onOpenPreviousQuestion,
}) => {
  const modalRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [shareUrl, setShareUrl] = useState("");

  useEffect(() => {
    if (correctAnswersCount >= 9 && showConfetti) {
      setShowModal(true);
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
    }
  }, [correctAnswersCount, showConfetti]);

  useEffect(() => {
    setShareUrl(window.location.href);
    setMessage(
      `Kuiz'de ${category} kategorisinde ${correctAnswersCount} cevabı doğru bildim, sen de dene!`
    );
  }, [correctAnswersCount, category]);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" ref={modalRef}>
        {correctAnswersCount > 9 && <h2>Tebrikler! Tüm cevapları buldunuz!</h2>}
        <h3>
          <b>Skor Tablosu</b>
        </h3>

        <div className="answer-progress-container">
          <div className="answer-progress">
            <div
              className="answer-progress-bar"
              style={{ width: `${(correctAnswersCount / 10) * 100}%` }}
            >
              {correctAnswersCount}/10
            </div>
          </div>
          <p className="streak-p">Kazanma seriniz: {streakCounter}</p>
        </div>

        <div className="share-wrapper">
          <p>Arkadaşlarına meydan oku, skorunu paylaş!</p>
          <div className="share-icons">
            <div>
              <WhatsappShareButton url={shareUrl} title={message}>
                <WhatsappIcon size={41} round={true} />
              </WhatsappShareButton>
            </div>
            <div>
              <TwitterShareButton url={shareUrl} title={message}>
                <TwitterIcon size={41} round={true} />
              </TwitterShareButton>
            </div>
            <div>
              <TelegramShareButton url={shareUrl} title={message}>
                <TelegramIcon size={41} round={true} />
              </TelegramShareButton>
            </div>
            <div>
              <FacebookShareButton url={shareUrl} quote={message}>
                <FacebookIcon size={41} round={true} />
              </FacebookShareButton>
            </div>
          </div>
        </div>

        <button className="modal-close" onClick={onClose}>
          x
        </button>

        <div className="social-container">
          <p>Bizi sosyal medyadan takip et!</p>
          <div className="social-media-icons">
            <div className="social-button">
              <SocialIcon
                url="https://www.tiktok.com/@kuizfun"
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
            <div className="social-button">
              <SocialIcon
                url="https://www.instagram.com/kuiz.fun/"
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
            <div className="social-button">
              <SocialIcon
                url="https://www.youtube.com/@KuizFun"
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
            <div className="social-button">
              <SocialIcon
                url="https://www.facebook.com/profile.php?id=61564324414061"
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
