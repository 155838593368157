import React from "react";
import { useNavigate } from "react-router-dom";
import "./Hero.css";

const Hero = () => {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate(category);
  };

  return (
    <div className="hero-container">
      <div className="top-category">
        <div className="category">
          <img
            src="/assets/images/checkBoxes.png"
            alt="This Or That"
            onClick={() => handleCategoryClick("/omubumu")}
          />
          <span className="text">O mu Bu mu?</span>
        </div>

        {/*
  <div className="category">
    <img
      src="/assets/images/whoami.png"
      alt="WhoAmI"
      onClick={() => handleCategoryClick("/benkimim")}
    />
    <span className="text">Ben Kimim?</span>
  </div>
*/}

      </div>
      <div className="bottom-category"></div>
      <div className="category">
        <img
          src="/assets/images/soccer-und.png"
          alt="Soccer"
          onClick={() => handleCategoryClick("/futbol")}
        />
        <span className="text">Futbol</span>
      </div>
    </div>
  );
};

export default Hero;
