import intMovies from "./intMovies.json";
import intActors from "./intActors.json";
import footballClubs from "./footballClubs.json";
import footballPlayers from "./footballPlayers.json";
import games from "./games.json";
import intRecords from "./intRecords.json";
import lisChars from "./lisChars.json";
import localMovies from "./localMovies.json";
import stardewItems from "./stardewItems.json";
import intMusicians from "./intMusicians.json";
import gameConsole from "./gameConsole.json";
import intDirectors from "./intDirectors.json";
import intBillboardSongs from "./intBillboardSongs.json";
import localMusicians from "./localMusicians.json";
import localTSM from "./localTSM.json";
import spotify100 from "./spotify100.json";
import lpSongs from "./lpSongs.json";
import localRecords from "./localRecords.json";
import witcher from "./witcher.json";
import dotaHeroes from "./dotaHeroes.json";
import lolChamps from "./lolChamps.json";
import valorant from "./valorant.json";

export function handleFile(path) {
  if (path === "intMovies") {
    return intMovies;
  } else if (path === "intActors") {
    return intActors;
  } else if (path === "footballClubs") {
    return footballClubs;
  } else if (path === "footballPlayers") {
    return footballPlayers;
  } else if (path === "games") {
    return games;
  } else if (path === "intRecords") {
    return intRecords;
  } else if (path === "lisChars") {
    return lisChars;
  } else if (path === "localMovies") {
    return localMovies;
  } else if (path === "stardewItems") {
    return stardewItems;
  } else if (path === "intMusicians") {
    return intMusicians;
  } else if (path === "gameConsole") {
    return gameConsole;
  } else if (path === "intDirectors") {
    return intDirectors;
  } else if (path === "intBillboardSongs") {
    return intBillboardSongs;
  } else if (path === "localMusicians") {
    return localMusicians;
  } else if (path === "localTSM") {
    return localTSM;
  } else if (path === "spotify100") {
    return spotify100;
  } else if (path === "localRecords") {
    return localRecords;
  } else if (path === "lpSongs") {
    return lpSongs;
  } else if (path === "witcher") {
    return witcher;
  } else if (path === "dotaHeroes") {
    return dotaHeroes;
  } else if (path === "lolChamps"){
    return lolChamps;
  } else if (path === "valorant"){
    return valorant;
  } 
}

export default handleFile;
