import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import Navbar from "../../components/Navbar/Navbar";
import "./ThisOrThat.css";
import Modal from "../Modal/Modal";

function getQueryVariable(variable) {
  const params = new URLSearchParams(window.location.search);
  return params.get(variable); // Get the value of the query parameter
}

const ThisOrThat = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [score, setScore] = useState(0);
  const [streakCounter, setStreakCounter] = useState("0");
  const [showModal, setShowModal] = useState(false);
  const { date } = useParams();
  const totalQuestions = 10;
  const category = "O mu Bu mu";
  const correctAnswersCount = score;

  useEffect(() => {
    const fetchQuestionsForDate = async () => {
      try {
        const response = await axios.post("https://kuiz.fun/api/getQuestionv2.php", {
          curdate: getQueryVariable('token'),
        });

        const fetchedQuestions = response.data;
        if (Array.isArray(fetchedQuestions) && fetchedQuestions.length > 0) {
          const formattedQuestions = fetchedQuestions.map((q) => ({
            text: q.question,
            correctAnswer: q.correct_answer,
            options: shuffleArray([
              { value: q.correct_answer, label: q.correct_answer, image: q.correct_img },
              { value: q.wrong_answer, label: q.wrong_answer, image: q.wrong_img },
            ]),
          }));
          setQuestions(formattedQuestions.slice(0, totalQuestions));
        } else {
          console.error("No valid questions found for the selected date.");
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestionsForDate();
  }, [date]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleAnswerSelection = (option) => {
    if (!selectedAnswer) {
      setSelectedAnswer(option.value);
      const isCorrect = option.value === questions[questionIndex].correctAnswer;
      setFeedback(isCorrect ? "correct" : "wrong");

      if (isCorrect) {
        setScore((prevScore) => prevScore + 1);
        handleWin();
      }
    }
  };

  const handleNextQuestion = () => {
    if (questionIndex < totalQuestions - 1) {
      setQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedAnswer(null);
      setFeedback("");
    } else {
      setIsModalOpen(true);
      setShowModal(true);
    }
  };

  if (loading) {
    return <div>Sayfa yükleniyor...</div>;
  }

  const currentQuestion = questions[questionIndex];

  if (!currentQuestion) {
    return <div>Başka soru yok.</div>;
  }

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleWin = () => {
    let currentStreak = parseInt(localStorage.getItem("gameStreak")) || 0;
    currentStreak += 1;
    localStorage.setItem("gameStreak", currentStreak.toString());
    localStorage.setItem("isChangeable", "true");
    setStreakCounter(currentStreak);
  };

  return (
    <>
      <Navbar />
      <div className="this-or-that-container">
        <h2 className="this-or-that-question">{currentQuestion.text}</h2>
        <div
          className="quiz-progress-bar-container"
          style={{ marginBottom: "40px" }}
        >
          <div
            className="quiz-progress-bar"
            style={{
              "--progress-width": `${
                (correctAnswersCount / totalQuestions) * 100
              }%`,
            }}
          >
            {correctAnswersCount}/{totalQuestions}
          </div>
        </div>
        <div className="this-or-that-options">
          {currentQuestion.options.map((option, index) => (
            <label
              key={index}
              className={`this-or-that-option-label ${
                feedback === "correct" && selectedAnswer === option.value
                  ? "correct"
                  : feedback === "wrong" && selectedAnswer === option.value
                  ? "wrong"
                  : ""
              }`}
              onClick={() => handleAnswerSelection(option)}
            >
              <input
                type="radio"
                name="answer"
                value={option.value}
                className="this-or-that-radio"
                checked={selectedAnswer === option.value}
                readOnly
              />
              <img
                src={option.image}
                alt={option.label}
                className="this-or-that-option-image"
              />
              {option.label}
            </label>
          ))}
        </div>
        <button
          className="next-btn"
          onClick={handleNextQuestion}
          disabled={!selectedAnswer}
        >
          Sonraki soru
        </button>
      </div>

      {showModal && (
        <Modal
          streakCounter={streakCounter}
          showConfetti={true}
          category={category}
          isOpen={isModalOpen}
          score={score}
          totalQuestions={totalQuestions}
          onClose={handleModalClose}
          correctAnswersCount={correctAnswersCount}
        />
      )}
    </>
  );
};

export default ThisOrThat;
