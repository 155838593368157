import React from "react";
import "./MobileNavbar.css";

const MobileNavbar = ({ isOpen, toggleMenu }) => {
  return (
    <>
      <div
        className={`mobile-menu ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <div className="mobile-menu-container">
          <a className="logo-item" href="/">
            <img
              className="mobile-logo"
              href="anasayfa"
              src="/assets/images/logoKuiz.png"
              alt="anasayfa"
            />
          </a>
          <ul>
            <li>
              <a className="menu-item" href="futbol">
                <img src="/assets/images/trophy.svg" alt="futbol" />
                Futbol
              </a>
            </li>
            {/* 
  <li>
    <a className="menu-item" href="benkimim" onClick={handleNavigation}>
      <img src="/assets/images/gamepad2.svg" alt="benkimim" />
      Ben Kimim
    </a>
  </li>
*/}
            <li>
              <a className="menu-item" href="omubumu">
                O mu Bu mu?
              </a>
            </li>
            <li>
              <a
                className="menu-item glow-text no-underline"
                href="https://kuiz.fun/blog/"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileNavbar;
